import {
    ActivityButtonVariants
} from '@containers/activity-router/data-grid-container/courses/course-buttons/activity-button/activity-button.types';
import { CellDimensions } from '@containers/activity-router/data-grid-container/data-grid-container.styled';

const activityButtonDefault = {
    color: 'neutral.800',
    alignItems: 'center',
    lineHeight: '21px',
    paddingRight: '12px',
    letterSpacing: '-0.002em',
    fontWeight: 400,
    fontSize: {
        base: 'sm',
        tablet: 'md'
    },
    _hover: {
        cursor: 'default',
        backgroundColor: 'white.0'
    }
};

export const ActivityButtons = {
    [ActivityButtonVariants.gridActivityButton]: {
        ...activityButtonDefault,
        h: CellDimensions.h,
        boxShadow: 'inset 0px -1px 0px #E2E8F0',
        justifyContent: 'space-between',
        paddingLeft: '22px',
        borderColor: 'neutral.200',
        _last: {
            borderBottomLeftRadius: '12px'
        }
    },
    [ActivityButtonVariants.assignModalActivityButton]: {
        ...activityButtonDefault,
        borderBottom: '1px solid',
        borderBottomColor: 'neutral.200',
        w: '100% !important',
        h: '58px',
        justifyContent: 'start',
        paddingLeft: '16px',
        pr: 0
    }
};
