import {
    CheckboxProps,
    FlexProps
} from '@chakra-ui/react';
import { CSSProperties } from 'react';

export enum GridCheckboxesVariants {
    studentCheckbox = 'studentCheckbox',
    topicCheckbox = 'topicCheckbox',
    activityCheckbox = 'activityCheckbox',
    assignedCheckbox = 'assignedCheckbox',
    dokCheckbox = 'dokCheckbox',
    circleCheckbox = 'circleCheckbox'
}

export type GridCheckboxProps = {
    checkboxProps: CheckboxProps
    styleProps?: FlexProps
    inputStyleProps?: CSSProperties
    showTooltipOnDisabled?: boolean
    tooltipLabel?: string
};
